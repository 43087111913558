::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  //opacity: 0;
  //display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
  margin: 15px 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  overflow: hidden;
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}
