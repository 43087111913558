@import "./styles/font";
@import "./styles/scroll";

@tailwind base;
@tailwind components;
@tailwind utilities;

a,
button {
  cursor: pointer;
  font-family: inherit;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
}

.container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;

  @media screen and (max-width: 1280px) {
    padding: 0 20px;
  }
}

html {
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  font-family: $font-family;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

.bottom-shadow {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 40%);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}

.top-shadow {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 25%);
  height: 100%;
  width: 140%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.react-autosuggest__container {
  position: relative;
  width: 100%;
  background-color: white;
}

.react-autosuggest__input {
  width: 100%;

  &::placeholder {
    color: #212121;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px; /* 150% */
  }
}
// .react-autosuggest__input {
//   width: 240px;
//   height: 30px;
//   // padding: 10px 20px;
//   font-family: Helvetica, sans-serif;
//   font-weight: 300;
//   font-size: 16px;
//   border: 1px solid #aaa;
//   border-radius: 4px;
// }

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  // display: block;
  // position: absolute;
  // top: 51px;
  // width: 280px;
  // border: 1px solid #aaa;
  // background-color: #fff;
  // font-family: Helvetica, sans-serif;
  // font-weight: 300;
  // font-size: 16px;
  // border-bottom-left-radius: 4px;
  // border-bottom-right-radius: 4px;
  // max-height: 300px;
  // overflow-y: auto;
  // z-index: 2;
  display: block;
  position: absolute;
  width: calc(100% + 60px);
  top: 40px;
  left: -45px;
  height: 200px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: white;
  overflow: auto;
  z-index: 10;

  .items {
    padding: 16px 0;
  }

  p {
    padding: 8px 16px;
    cursor: pointer;

    &:hover {
      background-color: lighten($color: #000000, $amount: 80);
    }
  }
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

// React Select
.phoneInputWIthCountry input {
  background-color: transparent;
  color: #efefefc2;
}

.phoneInputWIthCountry input:focus {
  outline: none;
}
