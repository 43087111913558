@font-face {
  font-family: NeoSans;
  src: url(/assets/fonts/NeoSansArabicLight.woff);
  font-weight: 300;
}

@font-face {
  font-family: NeoSans;
  src: url(/assets/fonts/Neo_Sans_Arabic_Regular.woff);
  font-weight: 400;
}

@font-face {
  font-family: NeoSans;
  src: url(/assets/fonts/NeoSansArabicMedium.woff);
  font-weight: 500;
}

@font-face {
  font-family: NeoSans;
  src: url(/assets/fonts/NeoSansArabicBold.woff);
  font-weight: 600;
}

$font-family: "NeoSans", sans-serif;
